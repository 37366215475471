<template>
    <section id="loading-estadisticas">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 242px)">
            <div class="row justify-center mx-0">
                <div class="col py-2">
                    <div class="bg-light-f5 d-flex border text-general2 py-1 br-5 px-2">
                        Valor de venta
                        <b class="ml-auto">
                            {{ convertMoney(producto.valor, id_moneda) }}
                        </b>
                    </div>
                    <div class="bg-light-f5 d-flex border text-general2 py-1 br-5 px-2 my-2">
                        Valor teórico
                        <b class="ml-auto">
                            {{ convertMoney(producto.teorico, id_moneda) }}
                        </b>
                    </div>
                    <div class="bg-light-f5 d-flex border text-general2 py-1 br-5 px-2 my-2">
                        Costo
                        <b class="ml-auto">
                            {{ convertMoney(producto.costo, id_moneda) }}
                        </b>
                    </div>
                </div>
            </div>
            <div class="col-auto py-2">
                <!-- <div class="bg-general3 text-white text-center br-5 px-2 py-3">
                        <p>
                            Descuento teórico
                        </p>
                        <p class="text-white f-600">
                            15%
                        </p>
                    </div> -->
                <div class="bg-general2 px-2 text-white br-5 my-2 text-center">
                    <p>
                        Utilidad <b>{{ utilidad }} %</b>
                    </p>
                </div>
            </div>
            <div class="row mx-0 px-2 my-2">
                <div class="col-6 px-0 mx-1">
                    <div class="bg-light-f5 border br-20 f-14 d-middle px-3 text-general2">
                        Ventas última semana
                        <b class="ml-auto">
                            {{ agregarSeparadoresNumero(ultimaSemanaDatos.cantidad) }}
                        </b>
                    </div>
                </div>
                <div class="col-4 ml-auto">
                    <div class="bg-light-f5 border px-3 br-20 f-14">
                        {{ separadorNumero(ultimaSemanaDatos.ventas) }}
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <echart ref="echart_semana" :options="ultimaSemana" />
                </div>
            </div>
            <div class="row mx-0 px-2 my-2">
                <div class="col-6 px-0 mx-1">
                    <div class="bg-light-f5 border br-20 f-14 d-middle px-3 text-general2">
                        Ventas últ. 4 semanas
                        <b class="ml-auto">
                            {{ agregarSeparadoresNumero(ultimaSemanasDatos.cantidad) }}
                        </b>
                    </div>
                </div>
                <div class="col-4 ml-auto">
                    <div class="bg-light-f5 border px-3 br-20 f-14">
                        {{ separadorNumero(ultimaSemanasDatos.ventas) }}
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <echart ref="echart_mes" :options="ultimaSemanas" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-db')

import Vue from 'vue'
import echarts from 'echarts'
import Productos from "~/services/productos/productos"
import {mapGetters} from 'vuex'

Vue.component('echarts',echarts);

export default {
    data(){
        let self = this
        return {
            pedidos:{},
            producto:{
            },
            utilidad:0,
            ultimaSemanaDatos:{
                cantidad: 0,
                ventas: 0,
                grafica: [],
            },
            ultimaSemanasDatos:{
                cantidad: 0,
                ventas: 0,
                grafica: [],
            },
            ultimaSemana: {
                responsive: true,
                maintainAspectRatio: false,
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: { type: 'shadow' },
                // },
                tooltip: {
                    trigger: 'axis',
                    show: true,
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // console.log(obj[0]);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.dia}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.cantidad)} ${obj[0].seriesName}</span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.separadorNumero(obj[0].data.ventas)} ventas </span>` + '<br>'
                        + '</div>'
                    }
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 2
                        }
                    },
                    show: false
                    // data: ['L', 'M', 'M', 'J', 'V', 'S', 'D' ],
                },
                yAxis: {
                    show:'false',
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 2
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#DBDBDB",
                        }
                    },
                },
                dataset: {
                    dimensions: ['dia','cantidad'],
                    source: [],
                },
                series: [
                    {
                        name: 'Cantidad',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [2, 3, 3, 4, 6, 7, 8],
                        itemStyle: {
                            normal: {
                                color: colorGeneral
                            },
                        },
                    },
                ]
            },
            ultimaSemanas: {
                responsive: true,
                maintainAspectRatio: false,
                tooltip: {
                    trigger: 'axis',
                    show: true,
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // console.log(obj[0]);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.semana}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.cantidad)} ${obj[0].seriesName}</span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.separadorNumero(obj[0].data.ventas)} ventas </span>` + '<br>'
                        + '</div>'
                    }
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 2
                        }
                    },
                    show: false
                    // data: ['L', 'M', 'M', 'J', 'V', 'S', 'D' ],
                },
                yAxis: {
                    show:'false',
                    axisLine: {
                        lineStyle: {
                            color: '#DBDBDB',
                            width: 2
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#DBDBDB",
                        }
                    },
                },
                dataset: {
                    dimensions: ['semana','cantidad'],
                    source: [],
                },
                series: [
                    {
                        name: 'Cantidad',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [2, 3, 3, 4, 6, 7, 8],
                        itemStyle: {
                            normal: {
                                color: colorGeneral
                            },
                        },
                    },
                ]
            },
            loading: null
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            cedis:'cedis/cedis',
            info_cedis:'cedis/info_cedis',
            id_producto: 'productos/productos/id_producto',
        }),

    },
    watch:{
        id_producto(val){
            this.estadisticas()
            this.ajustar_graficas()

        },
        id_cedis(val){
            this.estadisticas()
            this.ajustar_graficas()
        },
        id_moneda(val, oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.estadisticas()
            this.ajustar_graficas()
        }
    },
    mounted(){
        this.estadisticas()
    },
    methods:{
        async estadisticas(){
            try {
                if(this.id_producto){
                    this.loading = this.$loading(
                        {
                            lock: true,
                            target:`#loading-estadisticas`
                        });

                    const { data } = await Productos.estadisticas(this.id_producto,this.info_cedis.id, { id_moneda:this.id_moneda})
                    this.pedidos = data.pedidos
                    this.producto = data.producto
                    this.utilidad = data.utilidad

                    this.ultimaSemanaDatos = data.ultimaSemana
                    this.ultimaSemana.dataset.source = data.ultimaSemana.grafica

                    this.ultimaSemanasDatos = data.ultimaSemanas
                    this.ultimaSemanas.dataset.source = data.ultimaSemanas.grafica

                }
            } catch (error){
                this.error_catch(error)
            }finally{
                this.loading.close()
            }
        },
        ajustar_graficas(){
            setTimeout(() => this.$refs.echart_semana.ajustar(), 1000);
            setTimeout(() => this.$refs.echart_mes.ajustar(), 1000);
        }
    }
}
</script>
